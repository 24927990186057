<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-row>
          <v-col cols="12">
            <v-card class="pa-0">
              <v-card-title style="font-size: 14px;">
                Configuración general
                <v-spacer></v-spacer>
                <!-- Hago los chips manualmente ya que el componente FiltersSelected no funciona -->
                <div v-if="!isGeneralConfigExpanded">
                  <v-chip
                    v-for="(filter, key) in filtersApplied"
                    class="ma-2"
                    label
                    outlined
                    color="primary"
                    :key="key"
                  >
                    {{
                      `${filter.label}: ${
                        Array.isArray(filter.model)
                          ? getTextWhenFilterIsArray(filter)
                          : filter.model
                      }`
                    }}
                  </v-chip>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="toggleGeneralConfigCard">{{
                      isGeneralConfigExpanded
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}</v-icon>
                  </template>
                  <span>{{
                    isGeneralConfigExpanded ? "Minimizar" : "Expander"
                  }}</span>
                </v-tooltip></v-card-title
              >
              <v-expand-transition>
                <v-card-text class="pb-0" v-show="isGeneralConfigExpanded">
                  <v-form
                    v-model="isFormValid"
                    ref="config-general-form"
                    form="config-general-form"
                    id="config-general-form"
                    @submit.prevent="saveConfigGeneral()"
                  >
                    <v-row justify="center">
                      <v-col cols="4" class="py-0 px-0">
                        <v-subheader class="py-0 px-0">
                          Habilitar doble factor de autenticación
                          <v-switch
                            class="ml-4"
                            v-model="habilitaDobleFactor"
                            dense
                            outlined
                            @change="toggleDobleFactor()"
                          ></v-switch>
                        </v-subheader>
                      </v-col>
                      <v-col cols="2" class="py-0 px-0">
                        <v-text-field
                          v-model="diasVigenciaDobleFactor"
                          outlined
                          dense
                          label="Días de vigencia"
                          v-mask="'####'"
                          suffix="días"
                          ref="dias-vigencia-doble-factor"
                          :rules="
                            habilitaDobleFactor
                              ? rules.required.concat([
                                  rules.minMaxNumber(
                                    diasVigenciaDobleFactor,
                                    1,
                                    9999
                                  )
                                ])
                              : []
                          "
                          :disabled="!habilitaDobleFactor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-expand-transition>
              <v-expand-transition>
                <v-card-actions class="pt-0" v-show="isGeneralConfigExpanded">
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    :disabled="!isFormValid"
                    color="primary"
                    form="config-general-form"
                    type="submit"
                    :loading="loadingConfigBtn"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="pa-0">
              <v-card-title style="font-size: 14px;"
                >Configuración por usuario <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="toggleUserConfigCard">{{
                      isUserConfigExpanded
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}</v-icon>
                  </template>
                  <span>{{
                    isUserConfigExpanded ? "Minimizar" : "Expander"
                  }}</span>
                </v-tooltip></v-card-title
              >
              <v-expand-transition>
                <v-card-text v-show="isUserConfigExpanded">
                  <v-data-table
                    :headers="usuariosHeaders"
                    :items="filteredUsuariosConfig"
                    class="elevation-1"
                    :search="search"
                    :loading="isLoadingTableConfigUsuarios"
                    item-key="userId"
                  >
                    <template
                      v-slot:[`item.dobleFactorConfigHabilitado`]="{ item }"
                    >
                      <v-icon
                        v-if="item.dobleFactorConfigHabilitado"
                        small
                        color="primary"
                      >
                        {{ checkIcon }}
                      </v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            small
                            v-on="on"
                            @click="openModalRemoveQR(item)"
                          >
                            {{ qrCodeRemove }}
                          </v-icon>
                        </template>
                        <span>Volver a generar código QR</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            small
                            v-on="on"
                            @click="openModalEditConfig(item)"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar configuración</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="9">
                            <v-text-field
                              v-model="search"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalRemoveUserQR"
      v-model="modalRemoveUserQR"
      max-width="25%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary--text"
          >¿Regenerar QR?</v-card-title
        >
        <v-card-text class="text-left"
          >Se volverá a pedir el QR en el inicio de sesión para asociarlo a la
          aplicación elegida por el usuario.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAndReload" :disabled="isLoadingRemoveQRBtn"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="primary"
            dark
            @click="removeUserQRKey()"
            :loading="isLoadingRemoveQRBtn"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalEditConfigXUsuario"
      v-model="modalEditConfigXUsuario"
      max-width="45%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditConfigDobleFactorXUsuario
        :configUsuario="configUsuario"
        @closeAndReload="closeAndReload"
      ></EditConfigDobleFactorXUsuario>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditConfigDobleFactorXUsuario from "@/components/modules/administracion-sistema/EditConfigDobleFactorXUsuario.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "DobleFactorAutenticacion",
  components: {
    PageHeader,
    GoBackBtn,
    FiltersSelected,
    EditConfigDobleFactorXUsuario,
    Ayuda
  },
  directives: { mask },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.setConfigGeneral();
    this.filtersApplied = this.filtersApplied.filter(x => x.model.length > 0);
  },
  computed: {
    filteredUsuariosConfig() {
      return this.usuariosConfig.filter(item => {
        return item.dobleFactorConfigUsuario
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    }
  },
  data() {
    return {
      routeToGo: "ConfiguracionAdmSistema",
      title: enums.titles.DOBLE_FACTOR_AUTENTICACION,
      checkIcon: enums.icons.CHECK_OUTLINE,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      optionCode: enums.webSiteOptions.DOBLE_FACTOR_AUTENTICACION,
      isFormValid: false,
      habilitaDobleFactor: false,
      diasVigenciaDobleFactor: 0,
      rules: rules,
      usuariosHeaders: [
        {
          text: "Usuario",
          align: "start",
          value: "dobleFactorConfigUsuario",
          sortable: false
        },
        {
          text: "Doble factor de autenticación",
          align: "center",
          value: "dobleFactorConfigHabilitado",
          sortable: false
        },
        {
          text: "Días de vigencia",
          align: "end",
          value: "dobleFactorConfigDias",
          sortable: false
        },
        {
          text: "Fecha modificación",
          align: "start",
          value: "fechaModi",
          sortable: false
        },
        {
          text: "Usuario modificación",
          align: "start",
          value: "usuModi",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      usuariosConfig: [],
      search: "",
      isLoadingTableConfigUsuarios: false,
      searchIcon: enums.icons.SEARCH,
      isGeneralConfigExpanded: false,
      isUserConfigExpanded: false,
      filtersApplied: [],
      showFilters: false,
      loadingConfigBtn: false,
      editIcon: enums.icons.EDIT,
      qrCodeRemove: enums.icons.QRCODE_REMOVE,
      modalEditConfigXUsuario: false,
      configUsuario: {},
      modalRemoveUserQR: false,
      dobleFactorConfigId: null,
      isLoadingRemoveQRBtn: false
    };
  },
  methods: {
    ...mapActions({
      getConfigGeneralDobleFactor:
        "AdministracionSistema/getConfigGeneralDobleFactor",
      getConfigXUsuarioDobleFactor:
        "AdministracionSistema/getConfigXUsuarioDobleFactor",
      saveConfigGeneralDobleFactor:
        "AdministracionSistema/saveConfigGeneralDobleFactor",
      removeUserQRSecretKey: "AdministracionSistema/removeUserQRSecretKey",
      setAlert: "user/setAlert"
    }),
    getTextWhenFilterIsArray(currentFilter) {
      if (currentFilter.model.length > 0 && currentFilter.model.length <= 3) {
        return currentFilter.model.map(m => {
          return m.value ? m.value : m;
        });
      }
      if (currentFilter.model.length > 3) {
        let totalFiltros = currentFilter.model.length - 3;
        let firstThree = currentFilter.model.slice(0, 3).map(f => {
          return f.value ? f.value : f;
        });
        return `${firstThree} y ${totalFiltros} más`;
      }
    },
    async setConfigGeneral() {
      const response = await this.getConfigGeneralDobleFactor();
      this.habilitaDobleFactor = response.habilitado;
      this.diasVigenciaDobleFactor = response.dias;
      if (this.habilitaDobleFactor) {
        this.isUserConfigExpanded = true;
        this.setConfigXUsuario();
      } else this.isGeneralConfigExpanded = true;
      this.customizeFiltersApplied();
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.habilitaDobleFactor) {
        const habilitado = "Sí";
        this.filtersApplied.splice(0, 1, {
          key: "habilitaDobleFactor",
          label: "Habilitado",
          model: habilitado
        });
      } else {
        const habilitado = "No";
        this.filtersApplied.splice(0, 1, {
          key: "habilitaDobleFactor",
          label: "Habilitado",
          model: habilitado
        });
      }
      if (this.diasVigenciaDobleFactor) {
        this.filtersApplied.splice(1, 1, {
          key: "diasVigencia",
          label: "Días de vigencia",
          model: this.diasVigenciaDobleFactor
        });
      }
    },
    async setConfigXUsuario() {
      this.isLoadingTableConfigUsuarios = true;
      const response = await this.getConfigXUsuarioDobleFactor();
      this.usuariosConfig = response;
      this.isLoadingTableConfigUsuarios = false;
    },
    async saveConfigGeneral() {
      this.loadingConfigBtn = true;
      const data = {
        habilitado: this.habilitaDobleFactor,
        dias: this.diasVigenciaDobleFactor
      };
      try {
        const response = await this.saveConfigGeneralDobleFactor(data);
        if (response.status === 200) {
          this.loadingConfigBtn = false;
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.isGeneralConfigExpanded = false;
          this.customizeFiltersApplied();
          this.setConfigXUsuario();
        }
      } catch (error) {
        this.loadingConfigBtn = false;
      }
    },
    toggleDobleFactor() {
      if (!this.habilitaDobleFactor) {
        this.diasVigenciaDobleFactor = 0;
        this.usuariosConfig = [];
      } else {
        this.setConfigXUsuario();
        this.diasVigenciaDobleFactor = 30;
      }
    },
    toggleGeneralConfigCard() {
      this.isGeneralConfigExpanded = !this.isGeneralConfigExpanded;
    },
    toggleUserConfigCard() {
      this.isUserConfigExpanded = !this.isUserConfigExpanded;
    },
    openModalEditConfig(item) {
      this.modalEditConfigXUsuario = true;
      this.configUsuario = item;
    },
    closeAndReload() {
      this.modalEditConfigXUsuario = false;
      this.modalRemoveUserQR = false;
      this.setConfigXUsuario();
    },
    openModalRemoveQR(item) {
      this.modalRemoveUserQR = true;
      this.dobleFactorConfigId = item.dobleFactorConfigId;
    },
    async removeUserQRKey() {
      this.isLoadingRemoveQRBtn = true;
      try {
        const response = await this.removeUserQRSecretKey(
          this.dobleFactorConfigId
        );
        if (response)
          this.setAlert({
            type: "success",
            message:
              "QR regenerado con éxito. En el siguiente inicio de sesión se mostrará para volver a escanear."
          });
        this.closeAndReload();
        this.setConfigXUsuario();
      } catch (error) {}
      this.isLoadingRemoveQRBtn = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
